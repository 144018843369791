import { BRAND } from "@aspen/model";
import { systemDetection } from "./systemDetection";

const system = systemDetection();

/**
 * ADGM中Venture改名为Deal，前端匹配处理
 * @param type  Venture ｜ VENTURE ｜ venture
 * @return Deal ｜ DEAL ｜ deal
 */
export const convertVentureToDeal = (type: string) => {
  if (system === BRAND) {
    return type;
  }
  return type
    .replace(/VENTURE/g, "DEAL") // 替换大写VENTURE
    .replace(/Venture/g, "Deal") // 替换首字母大写Venture
    .replace(/venture/g, "deal"); // 替换小写venture
};
